.item{
    display: flex;
    padding: 6px 10px;
    align-items: center;
    justify-content: flex-start;

}

.icon {
    margin-right: 0.5rem;
    margin-top: 6px;
    /* color: white; */
    color: rgb(5, 19, 120);
}
.item a{
    color: rgb(5, 19, 120);
    text-decoration: none;
    font-size: 18px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.logout{
    padding: 8px;
    margin-top: 50px;
    display: flex;
    align-self: flex-start;
}

.item:hover{
    background-color: white;
    border-radius: 12px;
}

.logout_icon{
    padding: 2px;
    display: flex;
    align-self: flex-start;
    border-radius: 5px;
    color: rgb(5, 19, 120);
}

