.logout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
}

.chat-box {
    max-width: 350px;
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.chat-header {
    padding: 10px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.header_icon {
    width: 30px;
    padding: 10px;
    background-color: rgb(249, 190, 81);
    border-radius: 50%; 
    margin-right: 20px;
}

.header_icon i{
    color: rgb(74, 49, 3);
    font-size: 30px;
}

.chat-header h3, .chat-header h4{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin: 10px;
}