.App{
    overflow: hidden;
    height: 100vh;
}
.main {
    width: 100%;
    /* height: 100%; */
    display: inline-flex;
    background-color: #fff !important;
    padding: 0 !important;
    margin: 0 !important;
    /* background: linear-gradient(170deg, #6fb5ed ,#254e6f); */
}

.navmenu {
    /* background-color: #254e6f; */
    font-size: 23px;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Admin_menu {
    flex: 1;
    overflow-y: auto;
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    /* background-color: rgb(215, 185, 185); */
    height: 100vh;
    overflow: hidden;
}

.sidebar {
    display: flex;
    width: 14rem;
    height: 90%;
    justify-content: flex-start;
    /* align-items: start; */
    flex-direction: column;
    /* border: 1px solid black; */
    padding: 15px;
    margin: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.13);
}

.menu_icon {
    display: flex;
    align-self: flex-end;
    border-radius: 5px;
    color: rgb(5, 19, 120);
    /* border: none;
    outline: none; */
}

.user_profile {
    display: flex;
    width: 4rem;
    align-items: center;
    border-radius: 50%;
    /* margin-bottom: 1rem; */
    /* align-self: center; */
}

.user_profile>img {
    width: 100%;
    /* border-radius: 50%; */
}

.user_name h4 {
    text-align: center;
    margin: 0;
    color: rgb(5, 19, 120);
    font-weight: 300;
    font-size: 15px;
}

.groups {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.group h4 {
    /* color: white */
    color: rgb(5, 19, 120);
}

.menubars {
    display: none;
    margin: 0 0 0 20px;
}

.menubars i{
    font-size: large;
    color: black;
}
.machines{
    overflow-y: scroll;
    max-height: 95vh;
    scrollbar-width: none;
}
.DetailButton  {
    /* width: 35%; */
    padding: 10px;
    border-radius: 20px
}
.buttonText {
    color: white;
    text-align: "center";
    font-family: "poppins",sans-serif;
    font-size: 12px;
    margin: 0;
    font-weight: 600;
}


.powered {
    font-family: "roboto", sans-serif;
    color: #333;
    text-align: right;
    font-size: small; 
    position: absolute;
    bottom: 0;
    right: 20;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 82%;
    background-color: white;
  }

  .powered p{
    font-family: "roboto", sans-serif;
    color: #333;
    /* padding: 10px 10px 10px 0; */
    font-size: 11px;

  }
  




@media only screen and (max-width: 780px) {
    .logoimg img {
        width: auto
    }
    .menubars {
        margin: 0 0 0 20px;
        display: block;
    }
    .machines {
        max-height: 90vh;
    }
}