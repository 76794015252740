/* Toast container */
.toast {

    position: fixed;
    top: 20px;
    right: 20px;
    color: #fff;
    padding: 10px 25px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: slideIn 0.5s, fadeOut 0.5s ease-out 3s forwards;
  }

  .toast p{
    text-transform: capitalize;
    font-size: 16px;
  }
  
  /* Toast slide-in animation */
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  
  /* Toast fade-out animation */
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
  