.maincomp {
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.secondblock{
    width: 80%;
    margin: 10%;
    display: flex;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(3.5px);
    -webkit-backdrop-filter: blur(3.5px);
    border: 1px solid rgba(255, 255, 255, 0.13);
    border-radius: 15px;
}


.logo_img {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
} 
.logo_img img{
    align-self: center;
    align-items: center;
}
.logo_img h3{
    color: #252B42;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 }
 .comp_block {
    width: 40%;
    background-color: #ffff; 
    border-radius: 0 15px 15px 0;
    padding: 5%;
}

.fields1{
    width: 80%;
    margin: 0 10%;
    align-items: center;
    display: grid;
}

.fields1 label{
    color: #252B42;
    font-size: 18px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.comp_btn {
    height: 30px;
    margin-top: 20px;
    align-self: baseline;
    background-color: #252B42;
    border: none;
    border-radius: 5px;
    color: #ffff;
    font-weight: 700;
}

.Skip {
    position: absolute;
    bottom: 10px;
    right: 50px;
}