.user {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 0px;
    overflow-y: scroll;
    max-height: 80vh;
    scrollbar-width: none;
    padding-bottom: 50px;
}

.user_card {
    background-color: white;
    width: 260px;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 10px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  */
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.row_1,
.row_2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.user_name {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 40px 0;
    padding: 0;
}

.user_name1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
}

.user_card h5,
.user_card h4,
.user_name1 h5 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 8px 0;
    padding: 0;
    color: #3d4953;
}

.user_name h4 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    color: #3d4953;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    width: 100%;
}

.setting {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 10px 10%;
    padding: 10px;
    background-color: #c2dcf2;
    border-radius: 10px;
}

.user_name1 h3 {
    width: 100%;
    color: #254e6f;
    margin: 5px;
    font-size: 18px;
    text-align: center;
}

.search {
    margin: 0 0 0 55%;
    width: 40%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
    background-color: white;
    background-image: url('../Assets/Search.png');
    background-size: 20px;
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 8px 20px 8px 40px;
}

.page-data p {
    font-size: 12px;
    font-family: initial;
    font-weight: 300;
    color: rgb(5, 19, 120);
    float: left;
    margin: 10px 0px 0px 9%;
}

.pageinitation {
    float: right;
    padding-right: 9%;

}

.pageinitation button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgb(5, 19, 120);
    border-radius: 5px;
    justify-content: space-between;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-style: none;
    margin: 10px 1px 1px 1px;
}

.reportDownload {
    background-color: white;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 10px;
}

.reportDownload:hover {
    opacity: 0.5;
}

.modalBlock{
    min-width: 40%,
}

@media only screen and (max-width: 768px) {
    .setting {
        width: 90%;
        margin: 10px 2.5% 0 2.5%;
    }

    .user_card {
        width: 80%;
    }

    .modalBlock{
        min-width: 80%;
        position: sticky;
    }

}