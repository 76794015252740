.block {
  display: flex;
  /* Use flexbox to arrange items in a row */
  flex-wrap: wrap;
  /* Allow items to wrap to the next line if necessary */
  padding: 10px;
  margin-top: 20px;
  gap: 20px 10px;
  justify-content: space-evenly;
}

.stats_Card {
  background-color: white;
  padding: 10px;
  width: 150px;
  max-width: 150px;
  border-radius: 10px;
  margin: 5px;
  border: 2px solid #ccc;
  /* Add some margin between cards */
}

.icon_block {
  padding: 0 10px;
  width: max-content;
  height: 0;
}

.icon_block i {
  color: white;
  font-size: 25px;
  position: relative;
  top: -30px;
  padding: 10px;
  border-radius: 5px;
}

.value {
  width: auto;
}

.value h4,
.value h2 {
  margin: 1px;
  text-align: right;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.stats_Card p {
  margin: 0;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: gray;

}

.stats_Card hr {
  margin: 0;
}

.dateFilter {
  width: 90%;
  margin: 0 5%;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

.FromDate {
  z-index: 2;
  width: 250px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.FromDate label {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  color: #254e6f;
  margin: 0 10px;
  align-items: center;
}