/* TempratureStore.css */

.button-container {
  width: 30%;
  margin: 0 5%;
  display: inline-flex;
  justify-content: space-between;
  margin-top: 20px;
}

.add-button1 {
  padding: 10px 20px;
  background-color: #252B42;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  float: right;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, select {
  width: 95%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #252B42;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

