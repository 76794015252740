/* General styles */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  /* Wrapper for full-screen layout */
  .login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  /* Login container styling */
  .login-container {
    display: flex;
    /* max-width: 1100px; */
    /* width: 70%; */
    /* background-color: #1E2A7A; */
    background: linear-gradient(to bottom right, #1E2A7A, #6c63ff);
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .label {
    position: absolute;
    background-color: white;
    color: #da265b;
    margin: 0 10px;
    padding: 0 5px;
    font-size: 14px;
  }
  
  /* Left section styling */
  .left-section {
    flex: 1;
    padding: 50px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .left-section h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .left-section p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .buttons {
    display: flex;
    gap: 20px;
  }
  
  .learn-more,
  .watch-demo {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s;
  }
  
  .learn-more {
    background: white;
    color: #1E2A7A;
  }
  
  .learn-more:hover {
    background: #f0f4ff;
  }
  
  .watch-demo {
    background: transparent;
    border: 2px solid white;
    color: white;
  }
  
  .watch-demo:hover {
    background: white;
    color: #3b82f6;
  }
  
  /* Right section styling */
  .right-section {
    flex: 1;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
  
  .form-container {
    max-width: 350px;
    width: 100%;
    text-align: center;
  }
  
  .form-container h2 {
    margin-bottom: 10px;
    font-size: 1.6rem;
    color: #333;
  }
  
  .form-container p {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #555;
  }
  
  /* Form inputs */
  .form-input {
    width: 90%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 15px;
    font-size: 1rem;
  }
  
  .form-options {
    text-align: left;
    margin: 10px 0;
  }
  
  .form-options label {
    font-size: 0.9rem;
    color: #555;
  }
  
  /* Login button */
  .login-button {
    width: 90%;
    padding: 12px;
    background: #1E2A7A;
    color: white;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 10px;
    transition: all 0.3s;
    font-weight: 700;
  }
  
  .login-button:hover {
    background: #2563eb;
  }
  
  /* Sign-up text */
  .signup-text {
    margin-top: 20px;
    font-size: 0.9rem;
    color: #555;
  }
  
  .signup-text a {
    color: #3b82f6;
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .login-container {
      flex-direction: column; /* Stack sections vertically */
      /* max-width: 90%; Reduce the width for smaller screens */
    }
  
    .left-section,
    .right-section {
      flex: none;
      /* width: 100%; */
      padding: 20px;
    }
  
    .left-section h1 {
      font-size: 2rem; /* Reduce heading size */
    }
  
    .left-section p {
      font-size: 0.9rem; /* Reduce paragraph font size */
    }
  
    .buttons {
      flex-direction: column; /* Stack buttons vertically */
      gap: 10px;
    }
  
    .form-container h2 {
      font-size: 1.5rem; /* Reduce form heading size */
    }
  }
  
  /* For mobile devices (max-width: 480px) */
  @media (max-width: 480px) {
    .left-section h1 {
      font-size: 1.8rem;
    }
  
    .left-section p {
      font-size: 0.8rem;
      line-height: 1.4;
    }
  
    .buttons {
      gap: 5px;
    }
  
    .learn-more,
    .watch-demo {
      font-size: 0.9rem; /* Reduce button text size */
      padding: 8px 15px;
    }
  
    .form-container h2 {
      font-size: 1.2rem;
    }
  
    .form-container p {
      font-size: 0.8rem;
    }
  
    .form-input {
      font-size: 0.9rem;
      padding: 10px;
    }
  
    .login-button {
      font-size: 0.9rem;
      padding: 10px;
    }
  
    .signup-text {
      font-size: 0.8rem;
    }
  }