body {
  /* width: 100%;
  height: 100vh; */
  /* background-image: url("../Assets/bg.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  font-family: Georgia, 'Times New Roman', Times, serif;

}

.TvCode_block {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.Tv_Header {
  width: 60%;
  text-align: center;
}

.heading {
  color: #1e2a7a;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 34px;
  margin-left: 5%;
}

.inputSection {
  width: 60%;
  /* height: 20vh; */
  margin: 5% 20%;
  border-radius: 20px;
  background-color: #1e2a7a14;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

label {
  font-family: Georgia, 'Times New Roman', Times, serif;
  /* text-align: left; */
  margin-bottom: 1.5rem;
  font-weight: 600;
  color: rgb(50, 48, 61);
}

.name {
  padding: 8px;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  /* margin-right: 8px; */
}

.nextButton {
  /* width: 60%;
    margin: 0 20%; */
  padding: 8px 16px;
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #254e6f);
  background-color: #5AC6FA;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 20px;
}

.nextButton:hover {
  background-color: #0056b3;
}

.logo_Block {
  width: 40%;
  text-align: center;
}

.logo_Block img {
  width: 50%
}

.versionText {
  margin-top: 10px;
  font-size: 14px;
  color: #888;
}

.error {
  color: red;
  text-align: center;
  font-size: 20px;
  margin-left: 5%;
}

.qr_codeName{
  color: #254e6f;
  font-family: monospace;
  font-size: 24px;
  /* margin-left: 5%; */
}

.load {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust this value based on your layout */
}
/* Optional: Set a max-width for the image */



@media only screen and (max-width: 600px) {
  .TvCode_block {
    /* height: 80vh; */
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    justify-content: center;
  }

  .inputSection {
    height: auto;
    width: auto;
    margin: 5% 0;
  }

  .heading {
    margin-left: 0;
  }

  .Tv_Header {
    width: auto;
  }

  body {
    height: fit-content;
  }
}