.dashboard {
  width: 100%;
    row-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 0;
    height: 95%; 
    padding: 30px;
}
.dashboard1{
  display: flex;
  width: 95%;
  margin: 2%;
  gap: 10px;
  padding: 30px;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 90vh;
  scrollbar-width: none; 
}

.temprow1 {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 23%;
  min-width: 100px;
  text-align: center;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.07);
}


.card-body p {
  margin: 0;
  font-size: 19px;
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
  color: #666;
  font-weight: 700;
  text-transform: capitalize;

}


.cardHeader h2 {
  color: #252b42;
  text-align: center;
  margin: 5px 0 15px 0;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}

.card-body h3 {
  margin: 10px 0 0 0;
  color: #252b42;
}

.dashboardBlock {
  /* width: 90%; */
  margin: 0 5%;
  padding: 0 10px 10px 10px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(5.5px);
  backdrop-filter: blur(5.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.13);
}

.loadtemp{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}