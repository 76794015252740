

.tempStatsDetail{
  width: 320px;
  background-color: #E6EEF7;
  padding: 0 10px;
  border-radius: 20px;
  height: fit-content;
}

.deviceName{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
}

.stats1 h3{
  margin: 0 10px;
}

.tempStatsDetail h4{
  margin: 2px 10px;
  font-size: 15px;
  font-weight: 500;
}

.connectStatus{
  width: 90px;
  padding: 5px;
  opacity:0.7;
  border-radius: 10px;
  /* height: 30px; */
  align-items: center;
}

.connectStatus h4{
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  align-items: center;
}

.lastAlert{
  width: 75%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px;
  border-radius: 10px;
  align-items: center;
}

.footer{
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom:0
}

.TempActions{
  width: 15%;
  display: flex;
  justify-content: space-between;
}

.TempActions i{
  font-size: 18px;
  color: black;
  opacity: 0.7;
}
.alertBlock{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}