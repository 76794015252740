.device_table {
    max-height: 80vh;
    align-items: center;
    display: grid;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.device_table table {
    width: 98%;
    margin: 2px 1%;
    font-weight: 500;
    padding: 0;
    border-collapse: separate;
    border-spacing: 0 5px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* margin: 1% 1% 0 1%; */
}

.device_table th {
    position: sticky;
    top: 0;
    background-color: #252B42;
    color: #fff;
    font-style: normal;
    padding: 5px;
    font-family: poppins, sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.device_table {
    width: 100%;
    border-collapse: collapse;
}

.device_table td {
    padding: 5px;
    text-align: left;
    background-color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: poppins, sans-serif;
    border-bottom: 1px solid #ccc;
}

.device_table th {
    background-color: #252B42;
    color: #fff;
    /* text-align: center; */
}

.device_table tr {
    border-radius: 10px;
}

.device_table tr:hover {
    background-color: #ddd;
}

.device_table i {
    font-size: 18px;
    color: #252B42;
    cursor: pointer;
}

.addBlock {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    z-index: 2;
    position: fixed;
    top: 90vh;
    left: 95%;
}

.addBlock i {
    font-size: 30px;
    color: #252B42;
}

.inputField {
    width: 95%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin: 5px 0;
}

.fields {
    margin-top: 40px;
}

.fields label {
    color: #252B42;
    font-size: 18px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: 10px;
}

.sub_label {
    color: #252B42;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.loadsValue {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 10%;
    align-items: center;
}

.loadsFiels {
    width: 40%;
    padding: 3px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin: 5px 0;
    text-align: center;
}


.custom-dropdown {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin: 5px 0;
    cursor: pointer;
    /* position: relative;  */
}

.custom-dropdown::after {
    content: '';
    /* position: absolute; */
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #666;
    transform: translateY(-50%);
}

.custom-dropdown:focus {
    outline: none;
    border-color: #252B42;
}

.device_table i {
    padding: 0 10px;
}

.addBlock1 {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.error1 {
    position: fixed;
    top: 90vh;
    /* left: 55%; */
    color: red;
    text-align: center;
    font-size: 20px;
}

.addBlock1 i {
    position: fixed;
    /* top: 90vh; */
    bottom: 10px;
    font-size: 30px;
    right: 10px;
    color: #252B42;
}

.fields{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px 40px;
}

.shift_time {
    background-color: white;
    border-radius: 5px;
    text-align: center;
    border: 1px solid gray;
    padding: 2px;
    font-size: 17px;
    width: 30%;
}

@media only screen and (max-width: 768px) {

    .device_table th,
    .device_table td {
        font-weight: 400;
    }

    .device_table table {
        width: 95%;
        margin: 2px 2.5%;
        font-weight: 300;
    }

    .action {
        width: 45%;
    }

    .fields {
       
    display: grid;
    grid-template-columns: 1fr;
    gap: 2px 40px;
    }
    .shift_time {
        width: 28%;
    }

}