body {
    /* width: 100%;
    height: 100vh; */
    background-image: url("../Assets/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-family: Verdana, Geneva, Tahoma, sans-serif;

}

.Web_block {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.Web_Header {
    width: 60%;
    text-align: center;
}

.heading {
    color: #254e6f;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 34px;
    margin-left: 5%;
}

.inputSection {
    width: 60%;
    /* height: 20vh; */
    margin: 5% 20%;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

label {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* text-align: left; */
    margin-bottom: 1.5rem;
    font-size: 20px;
    font-weight: 600;
    color: #254e6f;
}

.inputSection p {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* text-align: left; */
    margin: 1rem;
    /* font-size: 15px; */
    font-weight: 400;
    color: #254e6f;
}

.name {
    padding: 8px;
    margin-bottom: .2rem;
    border-radius: 10px;
    width: 90%;
    text-align: center;
    border: 1px solid black;
    /* margin-right: 8px; */
}

.nextButton {
    width: 60%;
      margin: 10px 15%;
    /* padding: 8px 16px; */
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #254e6f);
    background-color: #5AC6FA;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 20px;
}

.nextButton:hover {
    background-color: #0056b3;
}

.logo_Block {
    width: 40%;
    text-align: center;
}

.logo_Block img {
    width: 50%
}

.versionText {
    margin-top: 10px;
    font-size: 14px;
    color: #888;
}

.error {
    color: red;
    text-align: center;
    font-size: 20px;
    margin-left: 5%;
}

.qr_codeName {
    color: #254e6f;
    font-family: monospace;
    font-size: 24px;
    /* margin-left: 5%; */
}

.load {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fff;
    width: 100%;
    /* Adjust this value based on your layout */
}

/* Optional: Set a max-width for the image */

.clearOTP{
    display: flex;
    justify-content: space-between;
}
.clearOTP h4{
    margin: 10px;
    text-align: left;
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 300;
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
   .Web_block{
    
    flex-direction: column;
    justify-content: center
   }

   .Web_Header {
    width: 80%;
    text-align: center;
}
.inputSection {
    width: 80%;
    /* height: 20vh; */
    margin: auto;
}

  
}