.col_1 {
    width: 46%;
    margin: 10px 2% 0 2%;
}

.row {
    width: 90%;
    margin: 0 5%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.device_name h4,
.device_name h3 {
    color: #254e6f;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin: 10px 0;
}

.excel i {
    font-size: 20px;
    color: #254e6f;
}

.machine_row2 {
    display: flex;
    flex-direction: row;
    width: 96%;
    justify-content: space-between;
    background-color: white;
    padding: 2%;
    /* margin-top: 30px; */
    border-radius: 10PX;
}

.custom-datepicker {
    width: 99%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    margin-top: 30px;
    display: inline-flex;
    flex-direction: row;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 98%;
    margin: 0 2% 0 0;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.showdateBlock {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.showdateBlock h5 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #003761
}

.hourData {
    width: 96%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2% 1% 0 1%;
    padding: 0 1%;
    align-items: center;
    background-color: #e1e9f2;
    border-radius: 10px;
    border: 2px solid gray;
}

.hourData h2 {
    padding: 12px 0;
    margin: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.hourRow {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #252B42;
    border-radius: 5px;
}

.hourRow h4{
    font-size: 16px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    color: white;
    padding: 2px;
    margin: 0;
    border-radius: 10px;
    font-weight: 200;
}
.hourRow1 h4 {
    font-size: 16;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    color: gray;
    padding: 2px;
    margin: 0;
}  
.table-data h4{
    color: rgb(40, 39, 39);
    font-weight: 500;
}
.report_download {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.report_download h3 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    margin: 0 1% 0 0;
    color: #254e6f;
}

.report_download i {
    color: #254e6f;
    text-align: center;
}


.custom-datepicker-report {
    width: 94%;
    border: 1px solid;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    margin-top: 10px;
    display: inline-flex;
    flex-direction: row;
    background-color: #ffffff;
}

.buttons {
    margin: 10px 0 0 0;
    width: 100%;
    justify-content: space-between;
    display: inline-flex;
}

.buttons button {
    padding: 5px;
    background-color: #003761;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
    /* border: 0px solid black; */
    border: none;
}

.dropdown-container {
    border: 1px solid black;
    border-radius: 5px;
}

.block1 {
    width: 100%;
    flex-direction: row;
    display: inline-flex
}

@media only screen and (max-width: 786px) {
    .block1 {
        width: 100%;
        display: block;
        height: 95vh;
        overflow-y: scroll;
        scrollbar-width: none;
    } 
    .col_1{
        width: auto;
    } 
}