.Header_temp {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* height: 8vh; */
}

.block_temp {
    width: 96%;
    /* height: 90vh; */
    margin: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 25px;
    /* overflow-y: scroll;
    scrollbar-width: none; */
}

.stats h2 {
    width: 30px;
    text-align: center;
    font-size: auto;
}

.temp_box {
    width: 300px;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    padding: 2px;
}

.currentTempHumi{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.colm_1,
.col_4,
.col_5,
.col_6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.colm_1 h5,
.col_2,
.col_4 h5 {
    margin: 4px 0;
    width: 90%;
    text-align: center;
}

.col_5 h5,
.col_6 h5 {
    margin: 4px 0;
    text-align: center;
}

.col_3 {
    margin: 10px 0;
    width: 100%;
    text-align: center;
    color: green;
}

.temp_humi {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.block-1 {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.block-2 {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 30%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}


.statsBox {
    background-color: rgb(132, 132, 239);
    width: 150px;
    padding: 5px;
    border-radius: 10px;
}

.statsBox h5 {
    margin: 0;
    color: black;
    text-align: center;
    font-weight: 500;
}

.statsBox h1 {
    text-align: center;
    margin: 5px 0;
}


/* *********************Details Page Css*************************** */

.bodyTemp {
    overflow-y: scroll;
    height: 90vh;
    scrollbar-width: none;
}

.tempDeciveDetail,
.tempChart,
.tempTable {
    width: 90%;
    margin: 1% 5% 0 5%;
    background-color: rgb(233, 240, 246);
    border-radius: 10px;
    padding: 10px 0;
}

.tempTable{
    max-height: 50vh;
    overflow: scroll;
    scrollbar-width: none;
}

.tempDeciveDetail h3 {
    margin: 5px 20px;
    color: rgb(34, 51, 84);
}

.tempDeciveDetail h4 {
    margin: 5px 20px;
    color: rgb(34, 51, 84);
    font-weight: 400;
}

.tempDeciveDetail i {
    width: 98%;
    text-align: right;
}

.table_block {
    width: 100%;
    align-items: center;
    text-align: center;
    border-collapse: collapse;
}

.table_block td,
.table_block th {
    padding: 8px;
}

.table_block th {
    background-color: rgb(233, 240, 246);   
     color: cornflowerblue;
}

.table_block tr:nth-child(odd) {
    background-color: #f2f2f2;
}
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
    max-width: 90%;
    text-align: center;
    position: relative;
}

.popup-content h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
}

.popup-content label {
    display: block;
    margin-bottom: 10px;
    text-align: left;
}

.popup-content input {
    width: 94%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.popup-content button {
    padding: 10px 15px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.popup-content button:nth-child(3) {
    background-color: #4CAF50;
    color: white;
}

.popup-content button:nth-child(4) {
    background-color: #f44336;
    color: white;
}

.popup-content button:hover {
    opacity: 0.9;
}
.dateFilter{
    width: 90%;
    margin: 0 5%;
    text-align: right;
}

@media only screen and (max-width: 400px) {
    .stats h2 {
        width: 10px;
        text-align: center;
        font-size: 18px;
    }
}