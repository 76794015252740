.temp_table {
  max-height: 95vh;
  align-items: center;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.temp_table table {
  width: 90%;
  margin: 2px 5%;
  font-weight: 500;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0 5px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  /* margin: 1% 1% 0 1%; */
}

.temp_table th {
  position: sticky;
  top: 0;
  background-color: #252B42;
  font-style: normal;
  font-weight: 800;
  padding: 10px;
}

.temp_table {
  width: 100%;
  border-collapse: collapse;
}

.temp_table th,
.temp_table td {
  padding: 5px;
  text-align: left;
  background-color: #fff;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.temp_table th {
  background-color: #252B42;
  color: #fff;
  /* text-align: center; */
}

.temp_table tr {
  border-radius: 10px;
}

.temp_table tr:hover {
  background-color: #ddd;
}

.temp_table i {
  font-size: 18px;
  color: #252B42;
  cursor: pointer;
}

.add-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #252B42;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.tableHeading{
  width: 100%;
}

.tableHeading h2{
  text-align: center;
  color: #252B42;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
